import React,  {Component} from 'react';
import {useEffect} from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import Carousel from 'react-bootstrap/Carousel';
import TextField from '@material-ui/core/TextField';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import PersonIcon from '@material-ui/icons/Person';
import SearchIcon from '@material-ui/icons/Search';
import SendIcon from '@material-ui/icons/Send';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';
import { green, purple } from '@material-ui/core/colors';
import Autocomplete from '@material-ui/lab/Autocomplete';
import queryString from 'query-string';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ReadMoreIcon from '@mui/icons-material/ReadMore';
import AssignmentIcon from '@mui/icons-material/Assignment';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import '../App.css';
import { Nav,Navbar,Button,Form,Row,Modal,Col} from 'react-bootstrap';
import Loader from "react-js-loader";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import {isMobile,isBrowser} from "react-device-detect";
import Zoom from 'react-reveal/Zoom';
import Bounce from 'react-reveal/Bounce';
import Card from 'react-bootstrap/Card';
import {  BarChart, Bar,LineChart, Line,Label, LabelList, PieChart, Pie, Sector, Cell, AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend,ResponsiveContainer } from "recharts";
import { isExpired, decodeToken }from "react-jwt";
const key = "MIICXAIBAAKBgQCiQ5lLUPoLiI3VI69VfZ32tWjsL6HvqzYEtUbxutptHb3PYhKcbqirJ2cADUyWBWpfWgAQyshSciov5PhskWF5wRyhxc0WOLuK72icFqOu2ZLE2TvRvAzjNT2TaBHBeeJ2t39u6pPBz9ejZuXc05AapG2Jh7HfCORkTsCIhwGydwIDAQABAoGAT1wYzMeF/RJuQV85mWcG9w8NKs53y68yxDoQ0ZBNaKCztaGSFwR5UzhZZsn"
const CryptoJS = require("crypto-js");


const COLORS = ['#00934c', '#e89900', '#e40001'];

const COLORSCHART = ['#00934c', '#e89900', '#e40001'];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 2;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${(percent * 100).toFixed(2)}%`}
    </text>
  );
};

var datapie= [];
var datapieload= [];
var datalinechart= [];
var datalinechartload= [];

var errocode = 0;


class Buycredit extends Component{
    
    constructor(props) {
    super(props);
      this.state = {
  
        listjargon: [],
        modalupdate: false,
        modaldelete: false,
        idjargon:0,
        wordjargon:'',
        loadershow:false,
        tokenexist:'',
        tokenvalue : '',
        wbslink:'',
        nbreuser:0,
        nbrecenter:0,
        nbreaudio:0,
        audionotdiarized:0,
        credit:0,
        startertime:0,
        starterprice:0.00,
        businesstime:0,
        businessprice:0.00,
        enterprisetime:0,
        enterpriseprice:0.00,
        idtypeuser:0,
        mail:'',
        taxe:0.00,
        ttcstarter:0.00,
        ttcbusiness:0.00,
      };

  }

  componentWillMount() {

    let wbslinkload = sessionStorage.getItem('wbs');

    this.setState({ wbslink:wbslinkload})

    let lnguser = sessionStorage.getItem('langueuser');

    this.setState({ lng: lnguser})

    let token = sessionStorage.getItem('tokenunsaid');
    
    const myDecodedToken = decodeToken(token);

    if(myDecodedToken == null){
      this.setState({ typeuser: null})
      document.location.href = '/login';
      return;
    }
    const isMyTokenExpired = isExpired(token);

    if(isMyTokenExpired == true){
      this.setState({ typeuser: null})
      document.location.href = '/login';
      return;
    }

    const datauser = JSON.stringify(myDecodedToken.init).replace(/\"/g, "");

    
    var bytes  = CryptoJS.AES.decrypt(datauser.toString().replace(/p1L2u3S/g, '+' ).replace(/p1L2u3S/g, '+' ).replace(/s1L2a3S4h/g, '/').replace(/e1Q2u3A4l/g, '=').toString(), key);
    var originaldata = bytes.toString(CryptoJS.enc.Utf8);
    var userdata  = originaldata.split('-');

    this.setState({
      idtypeuser: parseInt(userdata[1]),
      taxe:parseFloat(userdata[7])
    })
    
  }

  reloaddata () {

    if(this.state.tokenvalue !== ''){

          this.setState({ reload: true})
          
    }

  }
    
  async componentDidMount() { 

    let typelangue = sessionStorage.getItem( 'langueuser');

    this.setState({ lng:typelangue})

    if(typelangue== 'FR'){

      document.title = "Acheter du crédit";

    }else{

      document.title = "Buy credit";

    }

    let token = sessionStorage.getItem('tokenunsaid');
  
    const myDecodedToken = decodeToken(token);

    token = JSON.stringify(myDecodedToken.uid).replace(/\"/g, "");


    fetch(this.state.wbslink + `listpricing`,{                  
          
      mode: 'cors',     
      method:'post',
      headers: {'Authorization': 'Bearer '+ token},
      })

      .then(res => {

        errocode = res.status;
  
        if (!res.ok) {
            
            return res.json().then(json => { throw json; });
  
        }
  
        return res.json();
  
      })
    .then(data => {

      var ttcstarteradd = (parseFloat(JSON.stringify(data[0].starterprice).replace(/\"/g, ""))/100) * this.state.taxe
      var ttcbusinessadd = (parseFloat(JSON.stringify(data[0].businessprice).replace(/\"/g, ""))/100) * this.state.taxe

      this.setState({
        startertime:parseInt(JSON.stringify(data[0].startertime).replace(/\"/g, "")),
        starterprice:parseFloat(JSON.stringify(data[0].starterprice).replace(/\"/g, "")),
        ttcstarter:ttcstarteradd + parseFloat(JSON.stringify(data[0].starterprice).replace(/\"/g, "")),
        businesstime:parseInt(JSON.stringify(data[0].businesstime).replace(/\"/g, "")),
        businessprice:parseFloat(JSON.stringify(data[0].businessprice).replace(/\"/g, "")),
        ttcbusiness:ttcbusinessadd + parseFloat(JSON.stringify(data[0].businessprice).replace(/\"/g, "")),
      });

      if(this.state.lng== 'FR'){

        document.title = "Achat de crédit";
  
      }else{
  
        document.title = "Buy credit";
  
      }

  })

  .catch(err => {
  
    if(errocode == 401 || errocode == 0){
      
      sessionStorage.removeItem("tokenunsaid");
      document.location.href = "/login";

      return

    }

    alert("Erreur :" + errocode + ", "+JSON.stringify(err.message).replace(/\"/g, ""))

    this.setState({
      loadershow: false
    })

  });  


  }


loaderdesactive(){

  this.setState({
    loadershow: false,
    selectedFile:'',
    idjargon: 0,
    wordjargon: '',
    textloading:''
  });
  
}


async updatepricing(){

    let token = sessionStorage.getItem('tokenunsaid');

    const myDecodedToken = decodeToken(token);

    token = JSON.stringify(myDecodedToken.uid).replace(/\"/g, "");

    // Create an object of formData 
    const formData = new FormData(); 

    formData.append("startertime",this.state.startertime);
    formData.append("starterprice",this.state.starterprice);
    formData.append("businesstime",this.state.businesstime);
    formData.append("businessprice",this.state.businessprice);

    fetch(this.state.wbslink + `updatepricing`,{                  
          
      mode: 'cors',     
      method:'post',
      headers: {'Authorization': 'Bearer '+ token},
      body: formData
      
      })

      .then(res => {

        errocode = res.status;

        if (!res.ok) {
            
            return res.json().then(json => { throw json; });

        }

        return res.json();

      })
    .then(data => {

      var ttcstarteradd = (parseFloat(JSON.stringify(data[0].starterprice).replace(/\"/g, ""))/100) * this.state.taxe
      var ttcbusinessadd = (parseFloat(JSON.stringify(data[0].businessprice).replace(/\"/g, ""))/100) * this.state.taxe

      this.setState({
        startertime:parseInt(JSON.stringify(data[0].startertime).replace(/\"/g, "")),
        starterprice:parseFloat(JSON.stringify(data[0].starterprice).replace(/\"/g, "")),
        ttcstarter:ttcstarteradd + parseFloat(JSON.stringify(data[0].starterprice).replace(/\"/g, "")),
        businesstime:parseInt(JSON.stringify(data[0].businesstime).replace(/\"/g, "")),
        businessprice:parseFloat(JSON.stringify(data[0].businessprice).replace(/\"/g, "")),
        ttcbusiness:ttcbusinessadd + parseFloat(JSON.stringify(data[0].businessprice).replace(/\"/g, "")),
      });

      if(this.state.lng== 'FR'){

        document.title = "Achat de crédit";
  
      }else{
  
        document.title = "Buy credit";
  
      }

    })

    .catch(err => {

      if(errocode == 401 || errocode == 0){
        
        sessionStorage.removeItem("tokenunsaid");
        document.location.href = "/login";

        return

      }

      alert("Erreur :" + errocode + ", "+JSON.stringify(err.message).replace(/\"/g, ""))

      this.setState({
        loadershow: false
      })

    });  



}

entrepriseabonnement (typeabonnement) {


}

typeabonnement (typeabonnement) {

  if(typeabonnement == 'enterprise'){

    if(this.state.mail.length ==0){

      if(this.state.lng === "fr"){
  
        alert("Veuillez saisir votre mail.");
        return;
  
      }else{
  
        alert("Please enter your email.");
        return;
  
      }  
  
    }
  
    if (typeof this.state.mail !== "undefined") {
  
        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
      
        if (!pattern.test(this.state.mail)) {
  
          if(this.state.lng === "fr"){
  
            alert("Veuillez saisir un mail valide s'il vous plait.");
            return;
      
          }else{
      
            alert("Please enter a valid email.");
            return;
      
          }  
      
        }
          
      }

  }

  this.setState({ typeabonnement:typeabonnement})

  this.setState({
    loadershow: true
  })

  let token = sessionStorage.getItem('tokenunsaid');

  const myDecodedToken = decodeToken(token);

  token = JSON.stringify(myDecodedToken.uid).replace(/\"/g, "");

  // Create an object of formData 
  const formData = new FormData(); 

  formData.append("offer",typeabonnement);

  fetch(this.state.wbslink + `payment`,{                  
        
    mode: 'cors',     
    method:'post',
    headers: {'Authorization': 'Bearer '+ token},
    body: formData
    
    })

    .then(res => {

      errocode = res.status;

      if (!res.ok) {
          
          return res.json().then(json => { throw json; });

      }

      return res.json();

    })
  .then(data => {

    this.setState({
      loadershow: false
    })

    window.open(JSON.stringify(data).replace(/\"/g, ""), '_blank');

    //document.location.href = JSON.stringify(data).replace(/\"/g, "");

  })

  .catch(err => {

    if(errocode == 401 || errocode == 0){
      
      sessionStorage.removeItem("tokenunsaid");
      document.location.href = "/login";

      return

    }

    alert("Erreur :" + errocode + ", "+JSON.stringify(err.message).replace(/\"/g, ""))

    this.setState({
      loadershow: false
    })

  });  

}

    
  render() {

          return (
              
            <div id="tarification">

            {this.state.loadershow == true ?
                
                <div className='loader'>

                  <Loader type="spinner-cub" bgColor={'#2A83E8'} title={this.state.textloading} color={'#2A83E8'} size={100} />

                </div> 

                :

                null

            }

            <Zoom>
              <p style={{fontWeight:"bold", fontSize:"50px", textAlign:"center", color:"#2A83E8", marginTop:"60px"}}>{this.state.lng === "FR"?  "Tarif": "Pricing"}</p>
            </Zoom>
            
            <div className='listsolution' style={{marginTop:"-10px"}}>

              <Bounce bottom>

                <Card style={{ width: '400px', height:"410px", borderRadius:'25px', marginLeft:isBrowser?'25px' :'0px'}} >                                               
                                                                                
                    <Card.Body >

                        <div  className='typeabonnement' style={{color:"white", fontSize:"20px", textAlign:"center", paddingTop:"10px"}}><p>Free</p><p>First Month</p></div>
                        
                        {/*
                        <div  className='timeabonnement' >
                          <p style={{color:"white", fontWeight:"bold",fontSize:"24px", textAlign:"center"}}>{this.state.lng === "FR"?  "Essai gratuit": "Free trial"}</p>
                          <p style={{color:"white", fontWeight:"bold",fontSize:"24px", textAlign:"center"}}>{this.state.lng === "FR"?  "de 30 minutes.": "to 30 minutes."}</p>
                          <p style={{color:"white", fontWeight:"bold",fontSize:"24px", textAlign:"center"}}>{this.state.lng === "FR"?  "sur 1 mois": "for one month"}</p>
                        </div>
                        */}

                        <div className='abonnementdetails'>

                          {/*<p style={{fontSize:"16px", textAlign:"center", marginTop:"10px"}}>{this.state.lng === "FR"?  "Max " + this.state.startertime.toLocaleString() + " min / mois": "Max " + this.state.startertime.toLocaleString() + " minutes / month"}</p>*/}
                          {/*<hr className="lineseparatorabonnement" ></hr>*/}
                          <p style={{fontSize:"16px", textAlign:"center", marginTop:"10px"}}>{this.state.lng === "FR"?  "60 minutes par mois": "60 minutes per month"}</p>
                     
                          <p style={{fontSize:"16px", textAlign:"center", marginTop:"-10px"}}>{this.state.lng === "FR"?  "2 Interlocuteurs ": "2 speakers"}</p>
                          
                          <p style={{fontSize:"16px", textAlign:"center", marginTop:"-10px"}}>{this.state.lng === "FR"?  "15 minutes par conversation": "15 minutes per conversation"}</p>

                          <p style={{fontSize:"16px", textAlign:"center", marginTop:"-10px"}}>{this.state.lng === "fr"?  "6 langues": "6 languages"}</p>

                          <p style={{fontSize:"16px", textAlign:"center", marginTop:"-10px"}}>{this.state.lng === "fr"?  "(Français, Anglais, Allemand, Arabe, Portugais, Espagnol)": "(French, English, German, Arabic, Portuguese, Spanish)"}</p>

                          <p style={{fontSize:"16px", textAlign:"center", marginTop:"-10px"}}>{this.state.lng === "FR"?  "Envoyer des fichiers audio et vidéo (100 Mo maximum)": "Upload audio & Vidéo files (100 Mbytes Max)"}</p>
                          
                          <p style={{fontSize:"16px", textAlign:"center", marginTop:"-10px"}}>{this.state.lng === "FR"?  "Transcription complète et rapport automatique": "Full transcription & automatic report"}</p>
                          
                          <p style={{fontSize:"16px", textAlign:"center", marginTop:"-10px"}}>{this.state.lng === "FR"?  "Éléments d'action et de décision de l'IA dans tous les rapports": "AI action & decision items in all reports"}</p>
                          <p style={{fontSize:"16px", textAlign:"center", marginTop:"-10px"}}>{this.state.lng === "FR"?  "Téléchargez le rapport en fichier DOC": "Download the report in DOC file"}</p>
                          <p style={{fontSize:"16px", textAlign:"center", marginTop:"-10px"}}>{this.state.lng === "FR"?  "Télécharger les enregistrements audio des réunions": "Download audio metting recordings"}</p>
                          <p style={{fontSize:"16px", textAlign:"center", marginTop:"-10px"}}>{this.state.lng === "FR"?  "Mises à jour des balises (nom du fichier, nom d'interlocuteur, commentaires)": "Tag updates (file name, speakers, comments)"}</p>
                          <p style={{fontSize:"16px", textAlign:"center", marginTop:"-10px"}}>{this.state.lng === "FR"?  "Lecture du fichier audio": "Playback"}</p>
                          <p style={{fontSize:"16px", textAlign:"center", marginTop:"-10px"}}>{this.state.lng === "FR"?  "Profils d'équipes : Managers, utilisateurs": "Team profiles: Managers, users"}</p>
                          <p style={{fontSize:"16px", textAlign:"center", marginTop:"-10px"}}>{this.state.lng === "FR"?  "Stockage de fichiers audio dans notre plateforme en France": "Audio file Storage in our platform in France"}</p>
                         
                          <div style={{height:"130px"}}>

                          </div>

                          {/*
                          <div className='boutonsubscribe' onClick={() => this.typeabonnement("starter")}>
                          <p  style={{color:"white", fontSize:"30px", textAlign:"center"}}>{this.state.lng === "FR"?  "Acheter": "Buy"}</p>
                          
                          </div>
                        */}

                        </div>

                        

                        
                    
                    </Card.Body>

                </Card>   

                <Card style={{ width: '400px', height:"410px", borderRadius:'25px', marginLeft:isBrowser?'25px' :'0px'}} >                                               
                                                                                
                  <Card.Body >

                      <div  className='typeabonnement' style={{color:"#CD7F32", fontSize:"20px", textAlign:"center", paddingTop:"10px"}}><p>Basic </p><p style={{marginTop:"-20px"}}>{parseFloat(this.state.ttcstarter).toLocaleString()} {this.state.lng === "FR"?  "€/mois": "€/month"} </p> <p style={{marginTop:"-20px"}}>{this.state.lng === "FR"?  "Facturé annuellement": "Billed Annually"}</p><p style={{marginTop:"-20px"}}>{this.state.lng === "FR"?  "Économisé 51 %": "Saved 51%"}</p></div>
                     
                      {/*
                      <div  className='timeabonnement' >
                        <p style={{color:"white", fontWeight:"bold",fontSize:"24px", textAlign:"center"}}>{this.state.lng === "FR"?  "Essai gratuit": "Free trial"}</p>
                        <p style={{color:"white", fontWeight:"bold",fontSize:"24px", textAlign:"center"}}>{this.state.lng === "FR"?  "de 30 minutes.": "to 30 minutes."}</p>
                        <p style={{color:"white", fontWeight:"bold",fontSize:"24px", textAlign:"center"}}>{this.state.lng === "FR"?  "sur 1 mois": "for one month"}</p>
                      </div>
                      */}

                      <div className='abonnementdetails'>

                        {/*<p style={{fontSize:"16px", textAlign:"center", marginTop:"10px"}}>{this.state.lng === "FR"?  "Max " + this.state.startertime.toLocaleString() + " min / mois": "Max " + this.state.startertime.toLocaleString() + " minutes / month"}</p>*/}
                        {/*<hr className="lineseparatorabonnement" ></hr>*/}
                        <p style={{fontSize:"16px", textAlign:"center", marginTop:"10px"}}>{this.state.lng === "FR"?  "600 minutes par mois": "600 minutes per month"}</p>
                     
                          <p style={{fontSize:"16px", textAlign:"center", marginTop:"-10px"}}>{this.state.lng === "FR"?  "8 Interlocuteurs ": "8 speakers"}</p>
                          
                          <p style={{fontSize:"16px", textAlign:"center", marginTop:"-10px"}}>{this.state.lng === "FR"?  "60 minutes par conversation": "60 minutes per conversation"}</p>

                          <p style={{fontSize:"16px", textAlign:"center", marginTop:"-10px"}}>{this.state.lng === "fr"?  "6 langues": "6 languages"}</p>

                          <p style={{fontSize:"16px", textAlign:"center", marginTop:"-10px"}}>{this.state.lng === "fr"?  "(Français, Anglais, Allemand, Arabe, Portugais, Espagnol)": "(French, English, German, Arabic, Portuguese, Spanish)"}</p>

                          <p style={{fontSize:"16px", textAlign:"center", marginTop:"-10px"}}>{this.state.lng === "FR"?  "Envoyer des fichiers audio et vidéo (100 Mo maximum)": "Upload audio & Vidéo files (100 Mbytes Max)"}</p>
                          
                          <p style={{fontSize:"16px", textAlign:"center", marginTop:"-10px"}}>{this.state.lng === "FR"?  "Transcription complète et rapport automatique": "Full transcription & automatic report"}</p>
                          
                          <p style={{fontSize:"16px", textAlign:"center", marginTop:"-10px"}}>{this.state.lng === "FR"?  "Éléments d'action et de décision de l'IA dans tous les rapports": "AI action & decision items in all reports"}</p>
                          <p style={{fontSize:"16px", textAlign:"center", marginTop:"-10px"}}>{this.state.lng === "FR"?  "Téléchargez le rapport en fichier DOC": "Download the report in DOC file"}</p>
                          <p style={{fontSize:"16px", textAlign:"center", marginTop:"-10px"}}>{this.state.lng === "FR"?  "Télécharger les enregistrements audio des réunions": "Download audio metting recordings"}</p>
                          <p style={{fontSize:"16px", textAlign:"center", marginTop:"-10px"}}>{this.state.lng === "FR"?  "Mises à jour des balises (nom du fichier, nom d'interlocuteur, commentaires)": "Tag updates (file name, speakers, comments)"}</p>
                          <p style={{fontSize:"16px", textAlign:"center", marginTop:"-10px"}}>{this.state.lng === "FR"?  "Lecture du fichier audio": "Playback"}</p>
                          <p style={{fontSize:"16px", textAlign:"center", marginTop:"-10px"}}>{this.state.lng === "FR"?  "Profils d'équipes : Managers, utilisateurs": "Team profiles: Managers, users"}</p>
                          <p style={{fontSize:"16px", textAlign:"center", marginTop:"-10px"}}>{this.state.lng === "FR"?  "Stockage de fichiers audio dans notre plateforme en France": "Audio file Storage in our platform in France"}</p>
                          <p style={{fontSize:"16px", textAlign:"center", marginTop:"-10px"}}>{this.state.lng === "FR"?  "En option : analyse de sentiments": "Optional: Sentiment analysis"}</p>
                         
                        <div style={{height:"100px"}}>

                        </div>

                        <div className='boutonsubscribe' onClick={() => this.typeabonnement("starter")}>
                        <p  style={{color:"white", fontSize:"30px", textAlign:"center"}}>{this.state.lng === "FR"?  "Acheter": "Buy"}</p>
                      </div>

                      </div>

                     

                      
                  
                  </Card.Body>

              </Card>   

              <Card style={{ width: '400px', height:"410px", borderRadius:'25px', marginLeft:isBrowser?'25px' :'0px' }}>                                               
                                                                                
                  <Card.Body >

                      <div  className='typeabonnement' style={{color:"#2A83E8", fontSize:"20px", textAlign:"center", paddingTop:"10px"}}><p>Business </p><p style={{marginTop:"-20px"}}>{parseFloat(this.state.ttcbusiness).toLocaleString()} {this.state.lng === "FR"?  "€/mois": "€/month"} </p> <p style={{marginTop:"-20px"}}>{this.state.lng === "FR"?  "Facturé annuellement": "Billed Annually"}</p><p style={{marginTop:"-20px"}}>{this.state.lng === "FR"?  "Économisé 31 %": "Saved 31%"}</p></div>
                      {/*
                      <div  className='timeabonnement' >
                        <p style={{color:"white", fontWeight:"bold",fontSize:"24px", textAlign:"center"}}>{this.state.lng === "FR"?  "Essai gratuit": "Free trial"}</p>
                        <p style={{color:"white", fontWeight:"bold",fontSize:"24px", textAlign:"center"}}>{this.state.lng === "FR"?  "de 30 minutes.": "to 30 minutes."}</p>
                        <p style={{color:"white", fontWeight:"bold",fontSize:"24px", textAlign:"center"}}>{this.state.lng === "FR"?  "sur 1 mois": "for one month"}</p>
                      </div>
                      */}
                      <div className='abonnementdetails'>

                        {/*<p style={{fontSize:"16px", textAlign:"center", marginTop:"10px"}}>{this.state.lng === "FR"?  "Max " + this.state.businesstime.toLocaleString() + "min / mois": "Max " + this.state.businesstime.toLocaleString() + " minutes / month"}</p>*/}
                        {/*<hr className="lineseparatorabonnement" ></hr>*/}
                        <p style={{fontSize:"16px", textAlign:"center", marginTop:"10px"}}>{this.state.lng === "FR"?  "1200 minutes par mois": "1200 minutes per month"}</p>
                     
                        <p style={{fontSize:"16px", textAlign:"center", marginTop:"-10px"}}>{this.state.lng === "FR"?  "20 Interlocuteurs ": "20 speakers"}</p>
                        
                        <p style={{fontSize:"16px", textAlign:"center", marginTop:"-10px"}}>{this.state.lng === "FR"?  "60 minutes par conversation": "60 minutes per conversation"}</p>

                        <p style={{fontSize:"16px", textAlign:"center", marginTop:"-10px"}}>{this.state.lng === "fr"?  "6 langues": "6 languages"}</p>

                        <p style={{fontSize:"16px", textAlign:"center", marginTop:"-10px"}}>{this.state.lng === "fr"?  "(Français, Anglais, Allemand, Arabe, Portugais, Espagnol)": "(French, English, German, Arabic, Portuguese, Spanish)"}</p>

                        <p style={{fontSize:"16px", textAlign:"center", marginTop:"-10px"}}>{this.state.lng === "FR"?  "Envoyer des fichiers audio et vidéo (100 Mo maximum)": "Upload audio & Vidéo files (100 Mbytes Max)"}</p>
                        
                        <p style={{fontSize:"16px", textAlign:"center", marginTop:"-10px"}}>{this.state.lng === "FR"?  "Transcription complète et rapport automatique": "Full transcription & automatic report"}</p>
                        
                        <p style={{fontSize:"16px", textAlign:"center", marginTop:"-10px"}}>{this.state.lng === "FR"?  "Éléments d'action et de décision de l'IA dans tous les rapports": "AI action & decision items in all reports"}</p>
                        <p style={{fontSize:"16px", textAlign:"center", marginTop:"-10px"}}>{this.state.lng === "FR"?  "Téléchargez le rapport en fichier DOC": "Download the report in DOC file"}</p>
                        <p style={{fontSize:"16px", textAlign:"center", marginTop:"-10px"}}>{this.state.lng === "FR"?  "Télécharger les enregistrements audio des réunions": "Download audio metting recordings"}</p>
                        <p style={{fontSize:"16px", textAlign:"center", marginTop:"-10px"}}>{this.state.lng === "FR"?  "Mises à jour des balises (nom du fichier, nom d'interlocuteur, commentaires)": "Tag updates (file name, speakers, comments)"}</p>
                        <p style={{fontSize:"16px", textAlign:"center", marginTop:"-10px"}}>{this.state.lng === "FR"?  "Lecture du fichier audio": "Playback"}</p>
                        <p style={{fontSize:"16px", textAlign:"center", marginTop:"-10px"}}>{this.state.lng === "FR"?  "Profils d'équipes : Managers, utilisateurs": "Team profiles: Managers, users"}</p>
                        <p style={{fontSize:"16px", textAlign:"center", marginTop:"-10px"}}>{this.state.lng === "FR"?  "Stockage de fichiers audio dans notre plateforme en France": "Audio file Storage in our platform in France"}</p>
                        <p style={{fontSize:"16px", textAlign:"center", marginTop:"-10px"}}>{this.state.lng === "FR"?  "En option : analyse de sentiments": "Optional: Sentiment analysis"}</p>
                       
                        <div style={{height:"100px"}}>

                        </div>

                        <div className='boutonsubscribe' onClick={() => this.typeabonnement("business")}>
                          <p  style={{color:"white", fontSize:"30px", textAlign:"center"}}>{this.state.lng === "FR"?  "Acheter": "Buy"}</p>
                        </div>
                        
                      </div>

                  </Card.Body>

              </Card>   

              <Card style={{ width: '400px', height:"410px", borderRadius:'25px', marginLeft:isBrowser?'25px' :'0px'}} >                                               
                                                                                
                  <Card.Body >

                      <div  className='typeabonnement' style={{color:"#FFD700", fontSize:"25px", textAlign:"center", paddingTop:"10px"}}>{this.state.lng === "FR"?  <p><p>Entreprise</p><p style={{marginTop:"-20px"}}>API</p><p style={{marginTop:"-20px"}}>
                        Prix personnalisé</p></p>: <p><p>Enterprise</p><p style={{marginTop:"-20px"}}> API</p><p style={{marginTop:"-20px"}}>Custom price</p></p>}</div>

                      {/*
                        <div  className='timeabonnement' >
                          <p style={{color:"white", fontWeight:"bold",fontSize:"24px", textAlign:"center"}}>{this.state.lng === "FR"?  "Essai gratuit": "Free trial"}</p>
                          <p style={{color:"white", fontWeight:"bold",fontSize:"24px", textAlign:"center"}}>{this.state.lng === "FR"?  "de 30 minutes.": "to 30 minutes."}</p>
                          <p style={{color:"white", fontWeight:"bold",fontSize:"24px", textAlign:"center"}}>{this.state.lng === "FR"?  "sur 1 mois": "for one month"}</p>
                        </div>
                      */}
                      <div className='abonnementdetails'>

                        {/*<p style={{fontSize:"16px", textAlign:"center", marginTop:"10px"}}>{this.state.lng === "FR"?  "Nbre min / mois personnalisé": "Personalized min / month"}</p>*/}
                        {/*<hr className="lineseparatorabonnement" ></hr>*/}
                        <p style={{fontSize:"16px", textAlign:"center", marginTop:"10px"}}>{this.state.lng === "FR"?  "Nombre de minutes personalisés par mois": "Personalized minutes per month"}</p>
                     
                      <p style={{fontSize:"16px", textAlign:"center", marginTop:"-10px"}}>{this.state.lng === "FR"?  "Nombre d'interlocuteurs personalisés": "Personalized speakers number"}</p>
                      
                      <p style={{fontSize:"16px", textAlign:"center", marginTop:"-10px"}}>{this.state.lng === "FR"?  "Nombre de minutes par conversation personalisés": "Personalized minutes per conversation"}</p>

                      <p style={{fontSize:"16px", textAlign:"center", marginTop:"-10px"}}>{this.state.lng === "fr"?  "6 langues": "6 languages"}</p>

                      <p style={{fontSize:"16px", textAlign:"center", marginTop:"-10px"}}>{this.state.lng === "fr"?  "(Français, Anglais, Allemand, Arabe, Portugais, Espagnol)": "(French, English, German, Arabic, Portuguese, Spanish)"}</p>

                      <p style={{fontSize:"16px", textAlign:"center", marginTop:"-10px"}}>{this.state.lng === "FR"?  "Envoyer des fichiers audio et vidéo (100 Mo maximum)": "Upload audio & Vidéo files (100 Mbytes Max)"}</p>
                      
                      <p style={{fontSize:"16px", textAlign:"center", marginTop:"-10px"}}>{this.state.lng === "FR"?  "Transcription complète et rapport automatique": "Full transcription & automatic report"}</p>
                      
                      <p style={{fontSize:"16px", textAlign:"center", marginTop:"-10px"}}>{this.state.lng === "FR"?  "Éléments d'action et de décision de l'IA dans tous les rapports": "AI action & decision items in all reports"}</p>
                      <p style={{fontSize:"16px", textAlign:"center", marginTop:"-10px"}}>{this.state.lng === "FR"?  "Téléchargez le rapport en fichier DOC": "Download the report in DOC file"}</p>
                      <p style={{fontSize:"16px", textAlign:"center", marginTop:"-10px"}}>{this.state.lng === "FR"?  "Télécharger les enregistrements audio des réunions": "Download audio metting recordings"}</p>
                      <p style={{fontSize:"16px", textAlign:"center", marginTop:"-10px"}}>{this.state.lng === "FR"?  "Mises à jour des balises (nom du fichier, nom d'interlocuteur, commentaires)": "Tag updates (file name, speakers, comments)"}</p>
                      <p style={{fontSize:"16px", textAlign:"center", marginTop:"-10px"}}>{this.state.lng === "FR"?  "Lecture du fichier audio": "Playback"}</p>
                      <p style={{fontSize:"16px", textAlign:"center", marginTop:"-10px"}}>{this.state.lng === "FR"?  "Profils d'équipes : Managers, utilisateurs": "Team profiles: Managers, users"}</p>
                      <p style={{fontSize:"16px", textAlign:"center", marginTop:"-10px"}}>{this.state.lng === "FR"?  "Stockage de fichiers audio dans notre plateforme en France": "Audio file Storage in our platform in France"}</p>
                      <p style={{fontSize:"16px", textAlign:"center", marginTop:"-10px"}}>{this.state.lng === "FR"?  "En option : analyse de sentiments": "Optional: Sentiment analysis"}</p>
                      <p style={{fontSize:"16px", textAlign:"center", marginTop:"-10px"}}>{this.state.lng === "FR"?  "Déploiement à l'échelle de l'organisation avec API": "Organization-wide deployment with API"}</p>
                      <p style={{fontSize:"16px", textAlign:"center", marginTop:"-10px"}}>{this.state.lng === "FR"?  "Intégration CRM à la demande": "CRM Integration on demand"}</p>

                        <div style={{height:"40px"}}>

                        </div>

                        <div className='boutonsubscribe' onClick={() => this.entrepriseabonnement("enterprise")}>
                          <p  style={{color:"white", fontSize:"30px", textAlign:"center"}}>{this.state.lng === "FR"?  "Personnalisé": "Personalized"}</p>
                        </div>

                      </div>
                      
                      
                  
                  </Card.Body>

              </Card>   

              </Bounce>

              </div>

              {parseInt(this.state.idtypeuser) == 2 ?

                <div className='d-flex justify-content-center' style={{marginTop:"40px",marginBottom:"40px"}}>

                <Card style={{ width: '350px', height:"200px", borderRadius:'25px', marginLeft:isBrowser?'25px' :'0px'}} >                                               
                                                                                  
                    <Card.Body >

                        <p style={{color: "#CD7F32",margin:'5px', textAlign:"center", fontSize:"25px"}}>Starter</p>

                        {/*
                        <div className="form-group" style={{margin:'5px'}}>
                          <label>{this.state.lng ==="FR" ? 'Crédit temps' : 'Maximum time'}</label>
                          <input type="number" className="form-control" value={this.state.startertime} onChange={(e) => this.setState({ startertime: e.target.value})} />
                        </div>
                        */}

                        <div className="form-group" style={{margin:'5px'}}>
                          <label>{this.state.lng ==="FR" ? 'Tarif' : 'Customer price'}</label>
                          <input type="number" className="form-control" value={this.state.starterprice} onChange={(e) => this.setState({ starterprice: e.target.value})} />
                        </div>
                        <div className='d-flex justify-content-center'>
                                              
                          <Button className="btn" style={{ background: "#CD7F32", border: 0,margin:"10px",right: 0, width:'150px'}} onClick={() => this.updatepricing()} >{this.state.lng ==='FR'? 'Mettre à jour': 'Update'}</Button>

                        </div>

                    </Card.Body>

                </Card>   

                <Card style={{ width: '350px', height:"200px", borderRadius:'25px', marginLeft:isBrowser?'25px' :'0px'}} >                                               
                                                                                  
                    <Card.Body >

                        <p style={{color: "#2A83E8",margin:'5px', textAlign:"center", fontSize:"25px"}}>Business</p>

                        {/*
                        <div className="form-group" style={{margin:'5px'}}>
                          <label>{this.state.lng ==="FR" ? 'Business time' : 'Business time'}</label>
                          <input type="number" className="form-control" value={this.state.businesstime} onChange={(e) => this.setState({ businesstime: e.target.value})} />
                        </div>
                        */}

                        <div className="form-group" style={{margin:'5px'}}>
                          <label>{this.state.lng ==="FR" ? 'Tarif Business' : 'Business price'}</label>
                          <input type="number" className="form-control" value={this.state.businessprice} onChange={(e) => this.setState({ businessprice: e.target.value})} />
                        </div>
                        <div className='d-flex justify-content-center'>
                                              
                          <Button className="btn" style={{background: "#2A83E8", border: 0,margin:"10px",right: 0, width:'150px'}} onClick={() => this.updatepricing()} >{this.state.lng ==='FR'? 'Mettre à jour': 'Update'}</Button>

                        </div>

                    </Card.Body>

                </Card>   

                <Card style={{ width: '350px', height:"200px", borderRadius:'25px', marginLeft:isBrowser?'25px' :'0px'}} >                                               
                                                                                  
                    <Card.Body >

                       

                        <p style={{color: "#FFD700",margin:'5px', textAlign:"center", fontSize:"25px"}}>{this.state.lng === "FR"? "Entreprise" : "Enterprise"}</p>
                         {/*
                        <div className="form-group" style={{margin:'5px'}}>
                          <label>{this.state.lng ==="FR" ? 'Business time' : 'Business time'}</label>
                          <input type="number" className="form-control" value={this.state.enterprisetime} onChange={(e) => this.setState({ enterprisetime: e.target.value})} />
                        </div>

                        */}
                        
                        {/*
                        <div className="form-group" style={{margin:'5px'}}>
                          <label>{this.state.lng ==="FR" ? 'Tarif' : 'price'}</label>
                          <input type="number" className="form-control" value={this.state.enterpriseprice} onChange={(e) => this.setState({ enterpriseprice: e.target.value})} />
                        </div>
                        */}

                        <div className="form-group" style={{margin:'5px'}}>
                          <label>Email *</label>
                          <input type="email" className="form-control" aria-describedby="emailHelp" value={this.state.mail} onChange={(e) => this.setState({ mail: e.target.value})} />
                        </div>

                        <div className='d-flex justify-content-center'>
                                              
                          <Button className="btn" style={{background: "#2A83E8", border: 0,margin:"10px",right: 0, width:'170px'}} onClick={() => this.updatepricing()} >{this.state.lng ==='FR'? 'Envoyer le paiement': 'Send paiement'}</Button>

                        </div>

                    </Card.Body>

                </Card>   
                
                </div>
              
                :

                null
            
              }
                      
          </div>
                
          );
        }
    
}

export default Buycredit;